<template>
  <v-container fluid class="fill-height pa-6">
    <v-row class="align-self-start">
      <v-col cols="2" class="d-none d-md-flex"></v-col>
      <v-col>
        <v-row dense>
          <v-col class="d-flex flex-row">
            <v-avatar color="teal lighten-1" size="36">
              <v-icon color="white" v-text="'mdi-account-box'"></v-icon>
            </v-avatar>
            <div class="ml-2 text-h6 align-self-center" v-text="title"></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="6"
            v-for="(item, index) in items"
            :key="`area-${index}`"
          >
            <v-card color="teal lighten-1">
              <v-card-title class="py-2">
                <div color="green" class="body-1 white--text">
                  <v-icon
                    class="mr-2 text-h6 white--text"
                    v-text="item.icon"
                  ></v-icon
                  >{{ item.label }}
                </div>
              </v-card-title>
              <v-card-text class="pa-0">
                <v-card tile outlined>
                  <v-card-text class="pa-0 pl-1 py-2">
                    <v-list-item
                      v-for="(opt, index) in item.options"
                      :key="`opt-${index}`"
                      :href="opt.href"
                      dense
                      style="min-height: 30px"
                      @click="opt.click != null ? clickActions(opt.id) : ''"
                    >
                      <v-list-item-icon class="ma-0 mr-2 align-self-center">
                        <v-icon v-text="'mdi-hand-pointing-right'"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ opt.text }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-row class="align-self-end text-center">
          <v-col>
            <div v-text="'footer'"></div>
          </v-col>
        </v-row> -->
      </v-col>
      <v-col cols="2" class="d-none d-md-flex"></v-col>
    </v-row>
    <!-- change password -->
    <ChangePwdDialog
      :show="dialog.changePWD.active"
      :dialogProps="dialog.changePWD"
    ></ChangePwdDialog>
  </v-container>
</template>

<script>
import LoginVerify from '@util/common/loginVerify.js';
import ChangePwdDialog from '@component/dialog/ChangePwdDialog';

export default {
  components: { ChangePwdDialog },
  data () {
    return {
      title: '會員中心',
      logger: {},
      dialog: {
        changePWD: {
          active: false,
          mem_uuid: '',
          maxWidth: '400px'
        }
      },
      items: [{
        label: '會員資料維護',
        icon: 'mdi-account-cog-outline',
        options: [
          { text: '修改個人資料', href: '/member/profile' },
          { text: '變更密碼', click: 'changePWD' }
        ]
      },
      {
        label: '裝機需求單',
        icon: 'mdi-wrench-outline',
        options: [
          { text: '檢視需求單', href: '/installation/list' },
          { text: '申請裝機', href: '/installation/apply' }
        ]
      }
      ]
    };
  },
  methods: {
    /**
     * [clickActions]
     */
    clickActions (tg = '') {
      const _dialog = this.$options.data().dialog.changePWD;
      _dialog.active = true;
      _dialog.mem_uuid = this.logger.mem_uuid;
      this.$set(this.dialog, 'changePWD', _dialog);
    }
  },
  // beforeCreate () {
  //   console.log('[member]][beforeCreate] ... ');
  // },
  created () {
    // console.log('[member][created] ... ');
    // 目前登入者
    this.logger = LoginVerify.getLogger();
  }
  // beforeMount () {
  //   console.log('[member][beforeMount] ... ');
  // },
  // mounted () {
  //   console.log('[member][mounted] ... ');
  // },
  // beforeUpdate () {
  //   console.log('[member][beforeUpdate] ... ');
  // },
  // updated () {
  //   console.log('[member][updated] ... ');
  // }
};
</script>
<style scoped>
</style>
