<template>
  <v-dialog v-model="dialog.active" persistent :max-width="dialog.maxWidth">
    <v-card>
      <v-card-title class="py-4 pt-6 headline white--text justify-center"
        ><v-chip color="teal lighten-1" middle class="white--text">{{
          title
        }}</v-chip></v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="my-2 mb-4 pa-0 px-1">
        <v-container>
          <v-row dense>
            <v-col
              cols="12"
              class="mt-2 d-flex flex-row"
              v-for="item in pwdVars"
              :key="`input-${item.tg}`"
            >
              <div
                class="pt-1 subtitle-2 cyan--text text--darken-3 align-self-start"
                v-text="item.label"
              ></div>
              <v-text-field
                v-model="pwdVars[item.tg].value"
                :placeholder="item.placeholder"
                :rules="item.rules"
                class="ma-0 ml-2"
                dense
                password
                :hide-details="'auto'"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2 pb-5 justify-center">
        <v-btn color="green darken-1" outlined @click="clickBtnAction('submit')"
          >送出</v-btn
        >
        <v-btn color="green darken-1" outlined @click="clickBtnAction('close')"
          >關閉</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- dialog end -->
</template>
<script>
export default {
  props: {
    dialogProps: {
      type: Object,
      default: function () {
        return {
        };
      }
    }
  },
  data () {
    return {
      title: '變更密碼',
      dialog: this.dialogProps,
      pwdVars: {
        org: {
          tg: 'org',
          id: 'password',
          label: '舊密碼',
          value: '',
          rules: [
            val => !!val || '請輸入舊密碼'
          ],
          placeholder: '請填寫'
        },
        new: {
          tg: 'new',
          id: 'password',
          label: '新密碼',
          value: '',
          rules: [
            val => !!val || '請輸入新密碼'
          ],
          placeholder: '請填寫'
        },
        confirm: {
          tg: 'confirm',
          id: 'password',
          label: '再次輸入新密碼',
          value: '',
          rules: [
            val => !!val || '請輸入新密碼'
          ],
          placeholder: '請填寫'
        }
      }
    };
  },
  watch: {
    dialogProps: {
      handler (newProps, oldProps) {
        console.log('[ChangePwdDialog][watch] ... dialogProps');
        console.log(newProps);
        this.$set(this, 'dialog', newProps);
      },
      deep: true
    }
  },
  methods: {
    /**
     * [clickBtnAction]
     */
    clickBtnAction (tg = '') {
      switch (tg) {
        case 'submit':
          alert('執行變更密碼');
          break;
        case 'close':
          this.$set(this.dialog, 'active', false);
          break;
      }
    }
  },
  created () {
  }
};
</script>
